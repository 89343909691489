<template>
  <div class="row m-auto col-md-12 d-flex align-content-center justify-content-end p-0">
      <div class="row">
        <div class="m-3">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div class="m-3">
          <button
            type="button"
            @click.prevent="teste"
            class="btn btn_default d-flex justify-content-center"
          >
            <div v-if="!load">
               {{ id != "" ? "Salvar" : "Criar" }}
            </div>

             <half-circle-spinner
              :animation-duration="1000"
              :size="30"
              color="#fff"
              v-else
            />
          </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components: {
    HalfCircleSpinner,
  },
  props: ["id", "load"],
  methods: {
    teste() {
      this.$emit('sendMethod')
    }
  },
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
