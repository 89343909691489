<template>
    <div class="tab-pane p-0 fade col-md-12" id="clientdata" role="tabpanel" aria-labelledby="clientdata-tab">
        <div class="row">
            <div class="col-12 p-0">
              <ValidationProvider 
                v-slot="{  classes }"
                rules="required"
                tag="div"
              >
                <label class="mb-1 label_default" :class="classes" >Nome Completo</label>
                <input
                    placeholder="Nome"
                    type="text"
                    class="input_default form-control"
                    @input="$emit('update:name', $event.target.value)"
                    v-model="item.name"
                    :class="classes"
                />
              </ValidationProvider>
            </div>

            <div class="col-12 p-0">
            <div class="row m-0">
              <div class="col-4 pl-0">
                <ValidationProvider 
                  v-slot="{  classes }"
                  rules="required"
                  tag="div"
                >
                <label class="mb-1 label_default" :class="classes">CPF</label>
                <div>
                  <the-mask
                    v-if="load"
                    type="text"
                    class="inputValidate input_default form-control"
                    mask="###.###.###-##"
                    v-model="item.cpf"
                    placeholder="000.000.000-00"
                    :masked="false"
                    @input="$emit('update:cpf', item.cpf)"
                    :class="classes"
                  />
                  <half-circle-spinner
                    :animation-duration="1000"
                    :size="30"
                    color="#ff8c00"
                    class="my-0 mx-auto"
                    v-else
                  />
                </div>
                </ValidationProvider>
              </div>
              <div class="col-4 p-0">
              <ValidationProvider 
                v-slot="{ classes }"
                rules="required"
                tag="div"
              >
                <label class="mb-1 label_default" :class="classes">Telefone</label>
                <the-mask
                  v-if="load"
                  type="text"
                  class="input_default form-control"
                  mask="(##) #####-####"
                  @input="$emit('update:fone', item.cellPhone)"
                  placeholder="(xx) xxxxx-xxxx"
                  :masked="false"
                  v-model="item.cellPhone"
                  :class="classes"
                />
                <half-circle-spinner
                  :animation-duration="1000"
                  :size="30"
                  color="#ff8c00"
                  class="my-0 mx-auto"
                  v-else
                />
              </ValidationProvider>
              </div>
              <div class="col-4 pr-0">
                <ValidationProvider 
                  v-slot="{  classes }"
                  rules="required"
                  tag="div"
                >
                <label class="mb-1 label_default" :class="classes">Data de Nascimento</label>
                <input
                  placeholder="00/00/0000"
                  type="date"
                  class="input_default form-control"
                  @input="$emit('update:date', $event.target.value)"
                  v-model="item.birthDate"
                  :class="classes"
                />
                 </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="col-12 p-0 ">
            <div class="row m-0">
              <div class="col-6 pl-0">
                <label class="mb-1 label_default">E-mail</label>
                <input
                  placeholder="email@email.com"
                  type="email"
                  class="input_default form-control"
                 @input="$emit('update:email', $event.target.value)"
                  v-model="item.email"
                />
              </div>
              <div class="col-6 pr-0">
                <label class="mb-1 label_default"
                  >Título de Eleitor (Opcional)</label
                >
                <input
                  placeholder="0000 0000 0000"
                  type="number"
                  class="input_default form-control"
                  @input="$emit('update:voterRegistration', $event.target.value)"
                  v-model="item.voterRegistration"
                />
              </div>
            </div>
          </div>
            <div class="col-12 p-0 ">
              <div class="row m-0" id="rowTres">
                <div class="col-6 pl-0">
                  <ValidationProvider 
                    v-if="getServiceCategory()"
                    v-slot="{  classes }" 
                    rules="required">
                    <label class="mb-1 label_default" for="Placa">Placa</label>

                    <the-mask
                      :mask="'AAA-#G##'"
                      :placeholder="'AAA-#G##'"
                      :name="'Placa'"
                      :tokens="hexTokens"
                      id="Placa"
                      class="input_default form-control"
                      @blur.native="getVehiclePlate($event.target.value)"
                      v-model="item.plate"
                      :class="classes"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="item.id">
                <label class="mb-1 label_default" for="exampleFormControlTextarea1">Anotações</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                  @blur="updateVal($event.target.value)"
                  v-model="item.anotations"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import modal from "@/components/modal/modalDefault.vue";
import { TheMask } from "vue-the-mask";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  props: ['item', 'note', 'closeModalBool', 'changeUp', 'falseLoad'],
  components: {
    modal,
    TheMask,
    HalfCircleSpinner
  },
  data() {
    return {
      modal_md: 'modal_lg',
      load: false,
      hexTokens: {
        G: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }, // numero com Letras Mai
        "#": { pattern: /\d/ }, // Numero
        X: { pattern: /[0-9a-zA-Z]/ }, // Numeros e letras
        A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        T: { pattern: /[0-9a-zA-Z-@]/ },
        "!": { escape: true }
      }
    }
  },
  methods: {
    getServiceCategory() {
      let servicesNames = []
      let numberServices = this.$store.getters.buscaService.length
      let numberServicesOrder = this.$store.getters.getpedidos.servicesOrder.length
      let showFieldPlate = false
      if (numberServices > 0 && numberServicesOrder > 0) {
        this.$store.getters.getpedidos.servicesOrder.forEach(item => {
          servicesNames.push(item.serviceName) 
        })
        if (servicesNames != null) {
          this.$store.getters.buscaService.forEach(item => {
            if (servicesNames.includes(item.serviceName)) {
              if (item.categoryId == 18) {
                  showFieldPlate = true
              }
            }
          })
        } 
      }
      return showFieldPlate
    },
    updateVal(newVal) {
      this.$emit('update:note', newVal)
    },
    getVehiclePlate(newVal) {
      this.$emit('update:plate', newVal.toUpperCase())
    },
    changePlateUperCase(newVal) {
      this.$emit('update:plate', newVal.toUpperCase())
    }
  },
  watch: {
    '$store.getters.getSaveUser'(newValue, oldValue) {
      if(newValue === false) {
        this.load = false
        return
      }
        this.load = true
      // setTimeout(() => {
      // }, 1000)
    }
  },
}
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.tab-pane{
  align-items: center;
  display: flex;
  flex-direction: column;
}
.form-group{
  padding-top: 10px;
  width: 100%!important;
}

.row{
    width: 100%!important;
    padding-top: 10px;
}

span{
  color: #ff8c00;
}


img{
    padding-right: 5px;
}
#rowTres{
  padding-bottom: 25px;
}

  
</style>

