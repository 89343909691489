<template>
  <div
    class="tab-pane fade col-md-12"
    id="clientmovement"
    role="tabpanel"
    aria-labelledby="clientmovement-tab"
  >
    <div class="labelContainer">
      <label id="um">Pedido</label>
      <label id="dois">Data e Hora</label>
      <label id="tres">Acompanhamento</label>
    </div>
    <div class="row align-items-center">
      <div class="col-md-1" id="teste">
        <p class="servico_descricao mb-0">
          #16502
        </p>
      </div>
      <div class="col-md-3">
        <p id="progresso" class="servico_descricao mb-0">
          Em andamento
        </p>
      </div>
      <div class="col-4" id="teste">
        <p class="servico_descricao mb-0">
          29/06/2022 - 17:44
        </p>
      </div>
      <div class="eye col-4" id="eye">
        <i class="fas fa-eye" @click.prevent="showDpShow(indexs)"></i>
      </div>
    </div>
    <downtabs :class="`dp${index}`" :pedidos="item"> </downtabs>
  </div>
</template>

<script>
import modal from "@/components/modal/modalDefault.vue";
import downtabs from "@/components/cliente/tabs/downtabsClient.vue";

export default {
  components: {
    modal,
    downtabs
  },

  data() {
    return {
      modal_md: "modal_lg",
      eyeID: ""
    };
  },
  methods: {
    closeModal() {
      $("#modal_editCliente").modal("hide");
    },
    showDpShow(index) {
      var show;
      var iconShow;
      var currentIndx;
      if ($(".dropdownCp").is(":visible")) {
        $(".dropdownCp").velocity("slideUp", {
          display: "none",
          duration: 500
        });
        $(".service_name:not(:visible)").velocity("fadeIn");
      }

      if ($(`.dp${index}`).is(":visible")) {
        show = "slideUp";
        iconShow = "fadeIn";
      } else {
        show = "slideDown";
        iconShow = "fadeOut";
      }

      $(`.dp${index}`).velocity(show, {
        display: `${show == "slideUp" ? "none" : "flex"}`,
        duration: 500
      });
      $(`.service_name${index}`).velocity(iconShow, {
        display: `${iconShow == "fadeOut" ? "none" : ""}`,
        duration: 0
      });

      var currentIndx = index;
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.labelContainer {
  width: 100%;
}
#dois {
  padding-left: 40%;
}
#tres {
  float: right;
}
#eye {
  display: flex !important;
  justify-content: right;
  float: right !important;
}

.row {
  height: 45px !important;
  border-radius: 10px;
  background: #fff;
  border: 1px #84b9e1 solid;
  margin-bottom: 5px;
}
.col-4 {
  display: flex;
  justify-content: center;
}
.servico_descricao {
  color: #2474b2;
  font-weight: 400;
  font-size: 14px;
}
#progresso {
  color: gray !important;
}
#eyeContainer {
  padding-left: 5px;
}
</style>
