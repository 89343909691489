<template>
  <div class="col-md-12 p-0 d-flex align-items-center justify-content-between containerPedidos">
    <div class="col-md-10 d-flex align-items-center justify-content-between borderRight">
      <h4>{{ item.serviceName }}</h4>
      <h4> {{ item.quantityItens }} </h4>  
      <!-- copias -->
    </div>
    <div class="col-md-2 d-flex align-items-center justify-content-center">
       <h4> {{ valueConvert(item.subTotal) }} </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
}
</script>

<style scoped>
.containerPedidos {
  background: #206aad;
  height: 40px;
  /* border-radius: 8px; */
}
.containerPedidos h4 {
  font-weight: 300;
  margin: 0;
  font-size: 14px;
  color: #FFFFFF;
}

.borderRight {
  border-right: 2px solid #2c73b3;
  height: 100%;
}
.containerPedidos:first-child {
    /* background-color: lime; */
    border-radius: 8px 8px 0 0;
}
.containerPedidos:last-child {
    /* background-color: lime; */
      border-radius: 0 0 8px 8px;
}
</style>