<template>
  <modal
    :modalId="`modal_client`"
    :modalTitle="title"
    :load="false"
    :typeLg="'modal_wd'"
    @closeModal="closeModal"
  >
    <ValidationObserver ref="client">
      <div class="tab-content" id="myTabContent">
        <tabs
          @tabsMovie="changeTabs"
          :tabs="tabList"
          v-if="user.id"
          class="p-0"
        />
        <component :is="cp" :item.sync="user" />
        <actions
          :id="user.id"
          @closeModal="closeModal"
          @sendMethod="sendCreate"
          :load="loadSend"
        />
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
import modal from "@/components/modal/modalDefault.vue";
import clientdata from "@/components/cliente/tabs/clientData.vue";
import tabs from "@/components/tabs/modalTabs.vue";
import clientmovement from "@/components/cliente/tabs/clientMoviment.vue";
import actions from "@/components/cliente/acoes/botoes_rodape.vue";
import HTTP from "@/api_system";

export default {
  components: {
    modal,
    clientdata,
    tabs,
    clientmovement,
    actions
  },
  props: ["listUser", "id", "title", "user"],
  data() {
    return {
      loadSend: false,
      tabList: [
        {
          description: "Dados",
          tabName: "clientdata"
        }
        // {
        //   description: "Movimentação",
        //   tabName: "clientmovement"
        // }
      ],
      cp: "clientdata",
      falseLoad: false
    };
  },
  computed: {
    users() {
      return this.user;
    }
  },
  methods: {
    changeTabs(index, item) {
      this.cp = item.tabName;
    },
    closeModal() {
      $("#modal_client").modal("hide");
      this.$store.commit("setSaveUser", false);
    },
    sendCreate() {
      this.$refs.client.validate().then(res => {
        if (res) {
          this.requestClient(this.user);
        } else {
          this.toast("Preencha todos os campos obrigatorios", this.$toast);
        }
      });
    },
    requestClient(params) {
      this.loadSend = true;
      HTTP.post("services/app/Client/CreateOrEdit", params, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(res => {
          this.$store.commit("userCliente", res.data.result.id);
          this.loadSend = false;
          this.$emit("updateUser", false, res.data.result);
          this.closeModal();

          if (this.user.id) {
            this.toast("Dados atualizado com sucesso!", this.$toast);
            return;
          }

          this.toast("Cliente foi cadastrado com sucesso.", this.$toast);
        })
        .catch(err => {
          console.log(err);
          this.toast("Deu erro!", this.$toast.error);
          this.loadSend = false;
        });
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media (max-width: 230px) {
  #myTabContent {
    max-width: 200;
    overflow-x: auto;
    min-width: 115px;
  }
}

#myTabContent {
  width: 100%;
}

.conteudo {
  min-height: 450px;
  padding-top: 12px;
}

.fade:not(.show) {
  opacity: 1 !important;
}
</style>
